
.App{
  display: flex;
  flex-flow: column;
  /* height: 100%; */
  width: 100%;
  background: #000000;
}
@font-face {
  font-family: 'Cano';
  src: local('Cano'), url(./fonts/Cano.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}